import React, { useState, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthProvider from "./components/common/auth/AuthProvider";
import RequireAuth from "./components/common/auth/RequireAuth";
import Dashboard from "./pages/Dashboard";
import Lead from "./pages/Lead";
import Leads from "./pages/Leads";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

export default function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />

            <Route
              index
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="leads/:leadId"
              element={
                <RequireAuth>
                  <Lead />
                </RequireAuth>
              }
            />
            <Route
              path="leads"
              element={
                <RequireAuth>
                  <Leads title="Leads" />
                </RequireAuth>
              }
            />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
