import React from "react";
import { useNavigate } from "react-router-dom";
import { MailIcon, PhoneIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import Layout from "../components/common/Layout";

export default function Lead() {
  const navigate = useNavigate();
  const goBack = () => {
    // At the moment this is just the equivalent to hitting the back button
    // If we get to this from more than one page we might want to pass that
    // url as a prop
    navigate(-1);
  };
  return (
    <Layout>
      <div className="mx-4">
        <div className="flex justify-between align-center">
          <div>
            <a
              onClick={goBack}
              className="text-sm text-gray-600 font-semibold hover:text-indigo-900"
            >
              <span aria-hidden="true">&larr;</span> Back
            </a>
          </div>
          <div>
            <a
              className="text-sm text-gray-600 font-semibold hover:text-indigo-900"
              href="#"
            >
              Edit
            </a>
          </div>
        </div>

        <div className="my-6">
          <h1 className="text-3xl">Tom Harrington</h1>
          <h2 className="my-2">Owner @ AtomicBird Apps</h2>
          <div className="flex align-center">
            <div>
              <MailIcon
                className="flex-shrink-0 mt-1 mr-1.5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <div>
              <a className="flex align-center" href="mailto:tom@atomicbird.com">
                tom@atomicbird.com
              </a>
            </div>
          </div>
          <div className="flex align-center">
            <div>
              <PhoneIcon
                className="flex-shrink-0 mt-1 mr-1.5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <div>
              <span>(555) 555-5555</span>
            </div>
          </div>
        </div>

        <div className="flex divide-x">
          <div className="py-4 w-1/2 text-center bg-gray-100 rounded-l-lg">
            <button>New Task</button>
          </div>
          <div className="py-4 w-1/2 text-center bg-gray-100 rounded-r-lg">
            <button>New Activity</button>
          </div>
        </div>

        <div className="my-4">
          <div className="divide-y border-2 rounded-xl">
            <div className="flex items-center p-2 w-full">
              <div>
                <input
                  id=""
                  aria-describedby=""
                  name=""
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-2 flex items-center justify-between w-full">
                <div>
                  <p>Call Shane</p>
                  <p className="text-sm">Due on 06/01/22</p>
                </div>
                <div>
                  <button>
                    <DotsVerticalIcon className="h-5 w-5 text-gray-600" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center p-2 w-full">
              <div>
                <input
                  id=""
                  aria-describedby=""
                  name=""
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-2 flex items-center justify-between w-full">
                <div>
                  <p>Call Shane</p>
                  <p className="text-sm">Due on 06/01/22</p>
                </div>
                <div>
                  <button>
                    <DotsVerticalIcon className="h-5 w-5 text-gray-600" />
                  </button>
                </div>
              </div>
            </div>
            <div className="text-center py-3 rounded-b-xl bg-slate-100">
              View All
            </div>
          </div>
        </div>
        <div>
          <h2>Activities</h2>
        </div>
      </div>
    </Layout>
  );
}
