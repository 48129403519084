import React, { useState, Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import NewLeadModal from "../leads/NewLeadModal";
import { Link } from "react-router-dom";

export default function Layout({ children }) {
  const navigation = [
    { name: "Dashboard", href: "/", current: true },
    { name: "Leads", href: "/leads", current: false },
    { name: "Tasks", href: "/tasks", current: false },
    { name: "Settings", href: "/settings", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [isNewLeadModalOpen, setIsNewLeadModalOpen] = useState(false);

  const openNewLeadModal = () => {
    setIsNewLeadModalOpen(true);
  };

  const closeNewLeadModal = () => {
    setIsNewLeadModalOpen(false);
  };

  return (
    <div className="md:flex">
      <div className="hidden md:flex flex-col min-h-screen m-4">
        <h1>SoloCRM</h1>
        <nav className="flex flex-col w-48">
          {navigation.map((item) => (
            <Link key={item.name} to={item.href}>{item.name}</Link>
          ))}
        </nav>
      </div>
      <div className="md:hidden">
        <Disclosure as="nav" className="bg-white border-gray-200">
          {({ open }) => (
            <>
              <div className="flex justify-between items-center">
                <h1 className="mx-4 my-3">SoloCRM</h1>
                <div className="flex my-2">
                  <button className="mx-2" onClick={openNewLeadModal}>
                    New Lead
                  </button>
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
              <Disclosure.Panel className="sm:hidden">
                <div className="pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-indigo-50 border-indigo-500 text-indigo-700"
                          : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                        "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div className="md:flex md:flex-col w-full">
        <div className="hidden md:flex h-14 justify-between m-4">
          <div>Search:</div>
          <div>
            <button onClick={openNewLeadModal}>New Lead</button>
          </div>
        </div>
        <div>{children}</div>
      </div>

      <NewLeadModal
        isNewLeadModalOpen={isNewLeadModalOpen}
        closeNewLeadModal={closeNewLeadModal}
      />
    </div>
  );
}
