import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import Modal from "../common/Modal";

export default function SnoozeLeadModal({
  isSnoozeLeadModalOpen,
  closeSnoozeLeadModal,
}) {
  return (
    <Modal
      title="How long should alerts be snoozed?"
      isModalOpen={isSnoozeLeadModalOpen}
      closeModal={closeSnoozeLeadModal}
    >
      <div className="mt-4">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={closeSnoozeLeadModal}
        >
          Snooze
        </button>
      </div>
    </Modal>
  );
}
