import React from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";

export default function ActivityCell() {
  return (
    <div className="my-2 border-b flex">
      <p className="text-gray-900 text-sm py-2">
        Created <span className="font-bold text-black">Billford Billy</span> as
        a lead in the Freelancing pipeline
      </p>
      <button>
        <ChevronRightIcon className="h-4 w-4" />
      </button>
    </div>
  );
}
