import React from "react";
import Layout from "../components/common/Layout";
import { BellIcon } from "@heroicons/react/solid";
import LeadCell from "../components/leads/LeadCell";
import LeadStatCell from "../components/leads/LeadStatCell";
import Dropdown from "../components/common/Dropdown";

export default function Leads({ title }) {
  const pipelineOptions = [
    { title: "All Pipelines", id: 1 },
    { title: "Freelancing", id: 2 },
    { title: "My App", id: 3 },
  ];

  const sortOptions = [
    { title: "Name", id: 1 },
    { title: "Most Engaged With", id: 2 },
    { title: "Least Contacted", id: 3 },
  ];

  return (
    <Layout>
      <div className="flex mb-4 mx-4 justify-between">
        <h1 className="text-2xl">{title}</h1>

        <Dropdown
          title="All Pipelines"
          direction="right"
          options={pipelineOptions}
        />
      </div>

      <div className="mx-1 my-2">
        <input
          className="border-none w-full focus-outline-none"
          type="text"
          placeholder="Search..."
        />
      </div>

      <div className="mx-4">
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <LeadStatCell
            title="New Inbound Leads"
            icon={
              <BellIcon className="h-6 w-6 text-white" aria-hidden="true" />
            }
            number={2}
          />
          <LeadStatCell
            title="Leads That Need Attention"
            icon={
              <BellIcon className="h-6 w-6 text-white" aria-hidden="true" />
            }
            number={2}
          />
          <LeadStatCell
            title="Uncontacted Leads"
            icon={
              <BellIcon className="h-6 w-6 text-white" aria-hidden="true" />
            }
            number={2}
          />
        </div>
      </div>

      <div className="flex m-4">
        <Dropdown title="Sort by..." direction="left" options={sortOptions} />
      </div>

      <div>
        <LeadCell />
        <LeadCell />
      </div>
    </Layout>
  );
}
