import React from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";

export default function LeadStatCell({ title, icon, number, linkTo }) {
  return (
    <>
      <div className="md:hidden flex justify-between border-b pb-4 cursor-pointer">
        <div>
          {title}: {number}
        </div>
        <div>
          <button>
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
      <div className="hidden md:block relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
        <div>
          <div className="absolute bg-gray-500 rounded-md p-3">{icon}</div>
          <p className="ml-16 text-sm font-medium text-gray-500 truncate">
            {title}
          </p>
        </div>
        <div className="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p className="text-2xl font-semibold text-gray-900">{number}</p>

          <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <a
                href={linkTo}
                className="font-medium text-gray-600 hover:text-gray-500"
              >
                {" "}
                View all<span className="sr-only"> {title}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
