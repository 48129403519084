import * as React from "react";
import Layout from "../components/common/Layout";
import TaskCard from "../components/tasks/TaskCard";
import NurtureLeadCard from "../components/leads/NurtureLeadCard";
import ActivityCell from "../components/activities/ActivityCell";

export default function Dashboard() {
  return (
    <Layout>
      <div className="mb-6">
        <div className="flex items-center justify-between mx-4">
          <h1 className="text-2xl">Tasks</h1>
          <a
            href="#"
            className="text-sm text-gray-600 font-semibold hover:text-indigo-900"
          >
            View All <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
        <TaskCard />
        <TaskCard />
      </div>
      <div className="mb-6">
        <div className="flex items-center justify-between mx-4">
          <h1 className="text-2xl">Leads to Nurture</h1>
          <a
            href="#"
            className="text-sm text-gray-600 font-semibold hover:text-indigo-900"
          >
            View All <span aria-hidden="true">&rarr;</span>
          </a>
        </div>

        <NurtureLeadCard />
        <NurtureLeadCard />
      </div>
      <div className="mb-6">
        <h1 className="mx-4 text-2xl">Latest Activities</h1>
        <div className="m-4">
          <ActivityCell />
          <ActivityCell />
          <ActivityCell />
          <div className="py-4 text-sm">
            <a
              href="#"
              className="text-gray-600 font-semibold hover:text-indigo-900"
            >
              View all activities <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
