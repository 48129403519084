import React from "react";
import { UserIcon } from "@heroicons/react/outline";

export default function TaskCard() {
  return (
    <div className="m-4 bg-white overflow-hidden shadow rounded-t-lg flex justify-between items-center border-b-2 border-gray-300">
      <div className="p-4 sm:p-6">
        <span className="text-xs text-gray-300">Overdue by two days</span>
        <h2 className="text-xl py-2">Call Shane</h2>
        <div className="flex items-center text-xs pt-1 text-gray-600">
          <span>Shane Cowherd</span>
        </div>
      </div>
      <div>
        <input
          id=""
          aria-describedby=""
          name=""
          type="checkbox"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mx-4"
        />
      </div>
    </div>
  );
}
