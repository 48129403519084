import React, { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import SnoozeLeadModal from "./SnoozeLeadModal";

export default function NurtureLeadCard() {
  const [isSnoozeLeadModalOpen, setIsSnoozeLeadModalOpen] = useState(false);

  const openSnoozeLeadModal = () => {
    setIsSnoozeLeadModalOpen(true);
  };

  const closeSnoozeLeadModal = () => {
    setIsSnoozeLeadModalOpen(false);
  };

  return (
    <div className="m-4 bg-white overflow-hidden shadow rounded-lg flex justify-between items-center">
      <div className="px-4 py-5 sm:p-6">
        <span className="text-xs text-gray-300">Contacted two weeks ago</span>
        <h1 className="text-xl py-2">Tom Harrington</h1>
        <div className="mt-1">
          <button className="mr-2" onClick={openSnoozeLeadModal}>
            Snooze
          </button>
          <button className="mx-2">Call</button>
          <button className="mx-2">Email</button>
        </div>
      </div>
      <div className="mx-3">
        <button>
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>

      <SnoozeLeadModal
        isSnoozeLeadModalOpen={isSnoozeLeadModalOpen}
        closeSnoozeLeadModal={closeSnoozeLeadModal}
      />
    </div>
  );
}
