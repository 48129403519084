import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/outline";

export default function LeadCell() {
  const navigate = useNavigate();
  const goToLead = () => {
    navigate("/leads/1", { replace: true });
  };
  return (
    <div
      onClick={goToLead}
      className="m-4 bg-white overflow-hidden shadow rounded-lg flex justify-between items-center cursor-pointer"
    >
      <div className="px-4 py-5 sm:p-6">
        <h1 className="text-xl py-2">Tom Harrington</h1>
        <h3>Owner @ AtomicBird Apps</h3>
        <div className="mt-1">
          <button className="mr-2">Call</button>
          <button className="mr-2">Email</button>
        </div>
      </div>
      <div className="mx-3">
        <button>
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
}
