import React, { useState, useEffect } from "react";
import AuthContext from "../../../contexts/AuthContext";
import useLocalStorage from "../../../hooks/useLocalStorage";

export default function AuthProvider({ children }) {
  const baseUrl = process.env.NODE_ENV === 'production' ? "https://api.solocrm.app" : "http://localhost:8080";

  const [storedUser, setStoredUser, deleteStoredUser] = useLocalStorage('storedUser')
  const [token, setToken] = useLocalStorage('authToken');

  const loadStoredUser = () => {
    let user = null;
    try {
      user = JSON.parse(storedUser());
    } catch (e) {
      // We couldn't parse the stored user, let's clear it
      deleteStoredUser();
    }

    return user;
  };

  const persistStoredUser = (user) => {
    try {
      setStoredUser(JSON.stringify(user));
    } catch (e) {
      /* TODO: We couldn't store the user
         User will have to log in again after the page refreshes
         We should report it
       */
    }
  };

  const [user, setUser] = useState(loadStoredUser());

  useEffect(() => {
    persistStoredUser(user);
  }, [user]);

  const signIn = async (email, password) => {
    const encodedAuth = btoa(`${email}:${password}`);
    const response = await fetch(`${baseUrl}/auth/sign-in`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedAuth}`
      }
    });

    const payload = await response.json();
    const didSucceed = response.ok && payload.token.length

    if (didSucceed) {
      setToken(payload.token);
      setUser(payload.user);
    }

    return didSucceed;
  };

  let value = { user, signIn, token };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
